import React from "react";
import PropTypes from 'prop-types';
import { Link, Button } from "@lmig/lmds-react";
import LinkEnvironmentalize from "@lmig/dotcom-aspect-components/LinkEnvironmentalize";
import Heading from "@lmig/dotcom-aspect-components/Utility/Heading";
import LmdsIcon from "@lmig/dotcom-aspect-components/Icon/LmdsIcons";

const WhyShouldI = ({
  additionalClass = 'homepage',
  heading,
  subHeading,
  cardsTeal = undefined,
  textSections,
  buttonText = 'Start your quote',
  buttonVariant = 'secondary',
  buttonDomain = 'COMPARION',
  buttonHref = '',
  subHeadingMain = false,
}) => {
  const buttonProps = {};

  if (buttonHref?.includes("/quote")) {
    buttonProps.rel = "nofollow";
  }

  return (
    <div className={additionalClass}>
      <div className="whyComparion">
        {subHeadingMain ?
          <div className="headingBubbleWrapper">
            <Heading align="center" tag="h2" type="h3" className="mainTitleSub">{subHeading}</Heading>
          </div> :
          <div className="headingSection">
            <Heading align="center" tag="h2" type="h3" className="mainTitle">{heading}</Heading>
            <div className="subHeading">{subHeading}</div>
          </div>
        }
        <div className="whyChooseContent">
          <div className={additionalClass === "homepage" ? "textSectionsHome" : "textSections"}>
            {textSections.map(({ icon, title, text, linkText, linkHref, linkDomain = "COMPARION" }) => {
              const linkProps = {};

              if (linkHref?.includes("/quote")) {
                linkProps.rel = "nofollow";
              }

              return (
                <div className={additionalClass === "homepage" ? "textSectionHome" : "textSection"} key={title}>
                  <div className="iconWrapper">
                    <LmdsIcon icon={icon} size={{ base: '24', md: '32' }} />
                  </div>
                  <div className="textWrapper">
                    <h3 className="whyComparionSubtitle"><p className="whyComparionSubtitle">{title}</p></h3>
                    <p className="whyComparionText">{text}</p>
                    {!!linkHref && (
                      <LinkEnvironmentalize domain={linkDomain}>
                        <Link href={linkHref} {...linkProps} variant="standalone" className="textLink">
                          {linkText}
                        </Link>
                      </LinkEnvironmentalize>
                    )}
                  </div>
                </div>
              );
            })}

            {!!buttonHref && (<div data-theme="evolved">
              <LinkEnvironmentalize domain={buttonDomain} attribution>
                <Button variant={buttonVariant} href={buttonHref} className="whyShouldIButton" {...buttonProps}>
                  {buttonText}
                </Button>
              </LinkEnvironmentalize>
            </div>)}
            {cardsTeal && <div className="cardsTeal">
              {cardsTeal.map(text => {
                const firstWord = text.substring(0, text.indexOf(' '));
                const remainingText = text.substring(text.indexOf(' '));

                return (
                  <div className="cardTeal" key={text}>
                    <span className="firstWord">{firstWord}</span>
                    <span className="remainingText">{remainingText}</span>
                  </div>
                );
              })}
            </div>}
          </div>
        </div>
      </div>
      <style global jsx>{`@import './WhyShouldI.scoped.scss';`}</style>
    </div>
  );
};

WhyShouldI.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  additionalClass: PropTypes.oneOf(['homepage', 'product']),
  cardsTeal: PropTypes.arrayOf(PropTypes.string),
  textSections: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    linkText: PropTypes.string,
    linkHref: PropTypes.string,
  })).isRequired,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonDomain: PropTypes.string,
  buttonHref: PropTypes.string,
  subHeadingMain: PropTypes.bool,
};

export default WhyShouldI;